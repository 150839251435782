<template>
  <section class="mainText ">
    <b-container fluid="xl">
        <b-row class="maintText__row">
        <b-col cols="12" class="mainText__mainName side-gap">

          <h2 style="mainText__mainName--maintxtx">
            Lehet-e igazi a cyber szerelem?
          </h2>
          <p class="hashText">- Az online kapcsolatok valódiságáról</p>
        </b-col>
      </b-row>
        <b-row class="maintText__row">
            <b-col cols="12" lg="6">
            <div class="maintText__txt-content pb-lg-5 pb-2">
                <p class="text">
                Az online kapcsolatok lehetnek izgalmasak és mély érzelmekkel telítettek, ugyanakkor felmerülhet a kérdés: vajon mennyire valódiak? Íme néhány tanács, hogyan értékelheted az online kapcsolataid valódiságát. </p>

                <p class="text">
                Az internet forradalmasította a kapcsolatokat és az emberek közötti kommunikációt. A digitális korban az online világ lehetőséget nyújt arra, hogy emberek találkozzanak és kapcsolatokat építsenek ki a világ minden tájáról. Az online kapcsolatok lehetnek izgalmasak és mély érzelmekkel telítettek, ugyanakkor felmerülhet a kérdés: mennyire valódiak ezek a kapcsolatok? Íme néhány tanács, hogyan értékelheted az online kapcsolataid valódiságát.</p>
            </div>
            </b-col>
            <b-col cols="12" lg="6" class="order-4 order-lg-1">
              <img src="images/cyber_love.png" class="w-100 margin-top-sm-44 mb-4"/>
          </b-col>
            <b-col cols="12" lg="10" class="offset-lg-1 side-gap order-lg-2 order-1">
            <p class="text">
              <ul>
                <li class="py-3 d-sm-flex">
                  <span class="number">1</span>
                  <p class="text"><b>Ismerd fel a korlátokat: </b> Fontos megérteni, hogy az online kapcsolatoknak vannak korlátai. A virtuális térben nehéz teljes mértékben megismerni valakit, mivel a kommunikáció gyakran szűkül a szöveges üzenetekre vagy az online hang- és videóhívásokra. Az emberi kapcsolatok építéséhez szükség van a személyes találkozókra, a testbeszédre és az érzelmek közvetlen megéléseire is.</p> 
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">2</span>
                  <p class="text"><b>Építs bizalmat fokozatosan: </b>Ugyan romantikusnak hangozhat az első chatre megdobbanó szív, ne felejtsük el, hogy nem vagyunk virtuális Disney hercegnők, és a valóság limitációi elő kell, hogy hívják a józan ész hangját, hogy dacoljon magányos szívünk elemeivel. Az online kapcsolatokban fontos a bizalom fokozatos felépítése. Ismerkedj meg a másik személlyel, szánj időt a beszélgetésre és a megismerésre! Figyelj a kommunikációs stílusára, az összhangra és az esetleges visszatérő jelekre! Ha valami túl tökéletesnek hangzik, könnyedén lehet, hogy messze nem az: az online világban könnyebb manipulálni helyzetekkel és hamis személyiséget felvenni, ezért fontos figyelni a jellegzetes jelekre és ellentmondásokra. Ez a probléma nem újkeletű: gondolj csak Cyrano-ra!</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">3</span>
                  <p class="text"><b>(Továbbra is) Védd a személyes adataidat:  </b> Mint bármilyen online tevékenység során, az online kapcsolataidban is ügyelj a személyes adatok védelmére. Ne ossz meg túl korán és túl sok információt magadról! Fontos megőrizni a magánéleted határait és óvatosan bánni az olyan szenzitív adatokkal, mint például a lakcím, a telefonszám vagy a banki adatok.</p>
                </li>
                <li class="py-3 d-sm-flex">
                  <span class="number">4</span>
                  <p class="text"><b>Próbálj találkozni személyesen: </b> Ha egy online kapcsolatban mélyebben megismerted a másik személyt és úgy érzed, hogy közeledtek egymáshoz, fontolóra veheted a személyes találkozót. Azonban legyél körültekintő és óvatos, amikor először találkozol az online ismerőssel. Válassz biztonságos és nyilvános helyet, és értesíts valakit, hogy hol leszel és kivel találkozol!</p> 
                </li>
              </ul>
            </p>
            <p class="text">Most pedig képzeld el, hogy találkoztál valakivel, akivel látszólag nagyon egy hullámhosszon vagytok. Rendszeresen és sokat beszéltek, hallottad a hangját, láttad az arcát, és úgy érzed, igazán szeretnéd megismerni közelebbről is, akár romantikus kapcsolat keretein belül. </p><br><br>
            <p class="text">
                <b>Ebben a helyzetben a következő lépéseket tanácsoljuk:</b>
              <ul>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>1. Beszéljetek őszintén egymással az érzéseikről és az elvárásaikról!</b></p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>2. Szervezzetek egy biztonságos találkozót személyesen!</b></p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>3. Kérjétek ki a barátok és a család véleményét a kapcsolatról!</b></p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>4. Figyeljetek az intuícióitokra, és legyetek tisztában azzal, hogy az online kapcsolatoknak is vannak korlátai!</b></p>
                </li>
                <li class="py-3 d-sm-flex">
                  <p class="text"><b>5. Az online kapcsolatok lehetnek valódiak és értékesek, de fontos tudni, hogy a valódi, mély és tartós kapcsolatok építése időt és közvetlen személyes interakciót igényel. A cyber szerelem izgalmas lehet, de mindig tartsátok szem előtt a valóság és a biztonság fontosságát.</b></p>
                </li>
              </ul>
            </p>
        </b-col>
        </b-row>
    </b-container>
    <Articles :isFirst="false"/>
  </section>
</template>
<script>
import Articles from '../components/Articles.vue'

export default {
  components: {
    Articles
  },
  mounted(){

    this.setMetaTags({        
        title: "Lehet-e igazi a cyber szerelem?", 
        desc: "Az online kapcsolatok lehetnek izgalmasak és mély érzelmekkel telítettek, ugyanakkor felmerülhet a kérdés: vajon mennyire valódiak?", 
        keywords: null, 
        ogType: 'website', 
        ogTitle: "Lehet-e igazi a cyber szerelem?",
        ogImage: null, 
        ogImageWidth: null, 
        ogImageHeight: null, 
        ogDesc: "Az online kapcsolatok lehetnek izgalmasak és mély érzelmekkel telítettek, ugyanakkor felmerülhet a kérdés: vajon mennyire valódiak?"        
    })
  }
}
</script>